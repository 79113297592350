import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet";
import ReferComponent from "@components/ReferComponent";
import { defaultAction } from "@actions/defaultAction";
import { useDispatch, useSelector } from "react-redux";
import { initAppBrowser } from "@actions/initApp";
import { SHOW_SIGN_IN_POPUP_MODAL } from "@constants/actionTypes";
import loadable from "@loadable/component";
const CardCashSpinner = loadable(() =>
  import(
    /* webpackChunkName: "CardCashSpinner" */ "../components/CardCashSpinner"
  )
);
import "../styles/referPage.scss";

const MainContainer = styled.div`
  margin-top: 100px;
  background-color: #4a3f72;
  border-radius: 0px 0px 400px 0px;
  height: 100%;
  min-height: 1029px;

  @media (max-width: 1555px) {
    border-radius: 0px 0px 300px 0px;
  }
  @media (max-width: 1215px) {
    border-radius: 0px 0px 200px 0px;
  }
  @media (max-width: 875px) {
    border-radius: 0px 0px 120px 0px;
  }
  @media (max-width: 750px) {
    background-color: initial;
    min-height: auto;
    height: fit-content;
  }
`;

const MainContent = styled.div`
  margin: 0 auto;
  width: -moz-fit-content;
  padding-top: 80px;
  height: 100%;
  background-image: url(https://cdn.cardcash.com/website/ui/refer/refer-bg1.png);
  background-size: cover;
  border-radius: 0px 0px 400px 0px;

  @media (max-width: 1800px) {
    background-size: 121%;
    background-position-x: -87px;
    background-position-y: -85px;
  }
  @media (max-width: 1700px) {
    background-size: 132%;
    background-position-x: -135px;
    background-position-y: -92px;
  }
  @media (max-width: 1555px) {
    background-size: 150%;
    background-position-x: -203px;
    background-position-y: -92px;
    border-radius: 0px 0px 300px 0px;
  }
  @media (max-width: 1375px) {
    background-size: 169%;
    background-position-x: -121px;
    background-position-y: -92px;
  }
  @media (max-width: 1215px) {
    border-radius: 0px 0px 200px 0px;
    background-size: 193%;
  }
  @media (max-width: 1065px) {
    border-radius: 0px 0px 200px 0px;
    background-size: 236%;
    background-position-x: -324px;
    background-position-y: -92px;
  }
  @media (max-width: 875px) {
    border-radius: 0px 0px 120px 0px;
    background-size: 272%;
  }
  @media (max-width: 750px) {
    padding-top: 0px;
    background-image: none;
    background-size: auto;
    height: fit-content;
  }
`;

const Loader = styled.div`
  transform: translate(0%, 50%);
`;

const ReferAFriend = () => {
  const dispatch = useDispatch();
  const signedIn = useSelector(state => state.initAppReducer.signedIn);
  const signInAttempted = useSelector(
    state => state.initAppReducer.signInAttempted
  );

  const [spinnerState, changeSpinnerState] = useState(true);

  useEffect(() => {
    if (signInAttempted && !signedIn) {
      dispatch(defaultAction(SHOW_SIGN_IN_POPUP_MODAL, "pageRefer"));
      changeSpinnerState(false);
    }
    if (signInAttempted && signedIn) {
      changeSpinnerState(false);
    }
  }, [signedIn, signInAttempted]);

  useEffect(() => {
    dispatch(initAppBrowser(["messages"]));
  }, []);

  return (
    <MainContainer className="refer-a-friend-page">
      <Helmet
        title={"CardCash Referral Rewards Program"}
        meta={[
          {
            name: "description",
            content:
              "Everyone loves CardCash.com to save money every time they shop. Use this link to sign-up and you'll receive a coupon to use on your first order!"
          }
        ]}
      />
      <MainContent>
        {spinnerState && (
          <Loader>
            <CardCashSpinner />
          </Loader>
        )}
        {!spinnerState && <ReferComponent origin="page" />}
      </MainContent>
    </MainContainer>
  );
};

export default ReferAFriend;
